<template>
  <NavQuestion
    :heading="heading"
    :number="'4'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionLarge
        :letter="'a'"
        :option="'No'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-no-funeral-plan.png'"
        :selected="funeral.funeral_plan === false"
        @selected="save(false)"
      >
        <template v-slot:text>I do not have a pre-paid funeral plan.</template>
      </InputOptionLarge>
      <InputOptionLarge
        :letter="'b'"
        :option="'Yes'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-has-funeral-plan.png'"
        :selected="funeral.funeral_plan"
        @selected="save(true)"
      >
        <template v-slot:text
          >I have taken out a pre-paid funeral plan.</template
        >
        <template v-slot:input>
          <div class="mt30">
            <div class="input-container">
              <input
                v-model="planProvider"
                type="text"
                id="plan"
                placeholder="Plan Provider Name"
                class="input-standard-grey"
              />
              <label for="plan">Plan Provider Name</label>
            </div>
          </div>
        </template>
      </InputOptionLarge>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/funeral/organ_donor'"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionLarge from '@/common/ui/InputOptionLarge'

export default {
  name: 'FuneralPlan',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionLarge
  },
  computed: {
    funeral() {
      return this.$store.getters.funeral
    },
    isValidated() {
      if (this.funeral.funeral_plan !== null) return true
      return false
    },
    heading() {
      return 'Do you have a Pre-Paid Funeral Plan?'
    },
    subHeading() {
      return (
        ' People often pay for their funeral in advance using pre-paid funeral plans, if you have such a plan ' +
        'we will add the details to your Will so that your executors are easily able to contact the relevant provider.'
      )
    },
    forwardTo() {
      return '/funeral/wishes_option'
    }
  },
  beforeRouteLeave(to, from, next) {
    this.savePlanName()
    next()
  },
  data() {
    return {
      planProvider: ''
    }
  },
  methods: {
    save(value) {
      if (!value) {
        this.planProvider = ''
      }
      if (this.funeral) {
        this.$store.commit('funeral', {
          funeral_plan: value
        })
      }
    },
    savePlanName() {
      this.$store.commit('funeral', {
        funeral_plan_provider: this.planProvider
      })
    }
  }
}
</script>
